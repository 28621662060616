import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site, background } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
        background: file(relativePath: {eq: "market-wide.jpg"}) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 3600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return {...site.siteMetadata, backgroundImage: background.childImageSharp}
}

export default useSiteMetadata
