import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import './all.css'
import useSiteMetadata from '../hooks/use-site-metadata'
import { withPrefix } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const TemplateWrapper = ({ children }) => {
  const { title, description, backgroundImage } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en-NZ" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href="/favicon-48x48.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      </Helmet>
      <BackgroundImage
        Tag="div"
        fluid={backgroundImage.fluid}
        className="site-bg-image"
      >
        <div id="fb-root" />
        {children}
      </BackgroundImage>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
